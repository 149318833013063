<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Limites Globais</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12 card">
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData">
                <el-table-column label="Limite">
                  <template slot-scope="props">
                    <span>
                      <span>
                        <b>{{props.row.limit}}</b>
                      </span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="25" label="P. Jurídica">
                  <template slot-scope="props">
                    <span>
                      <base-input v-model="props.row.valueLegalPerson" v-on:input="handleChangeValue(props)"
                                  v-on:change="handleChangeValue(props)"/>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="25" label="P. Física">
                  <template slot-scope="props">
                    <span>
                      <base-input v-model="props.row.valueNaturalPerson" v-on:input="handleChangeValue(props)"
                                  v-on:change="handleChangeValue(props)"/>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="Tipo Op.">
                  <template slot-scope="props">
                    <span>
                      <span>{{opStatusToLabel(props.row.operationNature)}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :width="120" fixed="right" class-name="td-actions" label="Ação">
                  <template slot-scope="props">
                  <span v-if="getEditedState(props.$index)">
                    <button type="button" class="btn btn-danger" title="Salvar" @click="save(props)">
                      <i class="fas fa-edit"></i>
                    </button>
                  </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-12 pagination-info mt-3">
              <p class="text-warning">* APÓS ALTERAR ALGUM VALOR, O ÍCONE DE SALVAR SE TORNARÁ DISPONÍVEL.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from '../../../components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "../../../ws.service"

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        tableData: [],
        editStatus: []
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      getEditedState(index) {
        return this.editStatus[index].edited
      },
      handleChangeValue(props) {
        this.editStatus[props.$index].edited = true;
      },
      loadData() {
        postWs("/op-limit/list-global-op-limits",
          true, null,
          null,
          this.successLoadData,
          failWs);
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.editStatus = this.tableData.map(row => {
          return {edited: false}
        })
      },
      save(operationLimitScope) {
        this.$swal({
          title: 'Alterar Limite',
          text: "Você deseja realmente salvar as alterações para o limite " + operationLimitScope.row.limit + "?",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'sim',
          cancelButtonText: 'CANCELAR'
        }).then((result) => {
          if (result.isConfirmed) {
            postWs("/op-limit/set-op-limit", true,
              null, {
                limit: operationLimitScope.row.limit,
                naturalPersonValue: operationLimitScope.row.valueNaturalPerson,
                legalPersonValue: operationLimitScope.row.valueLegalPerson
              },
              (response) => {
                this.editStatus[operationLimitScope.$index].edited = false
              }, failWs)
          }
        })
      },
      opStatusToLabel(opType) {
        if (opType === 'SOLICITATION') {
          return 'SOLICITAÇÃO'
        } else if (opType === 'OUT') {
          return 'SAÍDA'
        }
        return '??'
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>
